import React from "react"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Hero from "../../components/Hero"

const CookiePolicyPage = () => {
  return <Layout>
    <Seo title="Cookie Policy" />
    <Hero title="Cookie Policy" />

    <section className="grid grid-cols-6 py-6 break-words">
      <article className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">

        <h3>Cosa sono i Cookie</h3>
        <p className="mb-2">I Cookie rappresentano delle porzioni di codice (piccoli file di testo) che, a seguito di una generazione dello script del sito web, vengono installate all’interno del browser di navigazione per permettere al <i>Titolare</i> di erogare il Servizio secondo le finalità prescritte. In alcuni casi, l’installazione di tali Cookie può richiedere il consenso dell’<i>Utente</i>.</p>
        <p>Alla luce della oggettiva complessità della materia e di tutte le tecnologie utilizzate per l’utilizzo dei Cookie, l’<i>Utente</i> può comunque contattare il <i>Titolare</i> (indirizzo mail: <i>francesco.latini.psy@gmail.com</i>) ogniqualvolta fosse interessato a ricevere approfondimenti relativi all’utilizzo dei Cookie da parte del sito internet del Dott. Francesco Latini.</p>

        <div className="grid grid-cols-6">
          <hr className="col-span-6 md:col-start-2 md:col-span-4 my-4 mx-5 text-grey h-0.5" />
        </div>

        <h3>Informativa</h3>
        <p className="mb-2">La presente informativa sui Cookie è parte integrante della più generale Informativa Privacy rilasciata dal Dott. Francesco Latini e consultabile nell’apposita sezione di questo <i>Sito</i>. Per eventuali chiarimenti sulle definizioni utilizzate consultare la prima parte della Informativa Privacy.</p>
        <p className="mb-2">Il sito del Dott. Francesco Latini, www.francescolatinipsicologo.it, è ospitato dalla piattaforma di hosting "Netlify", appartenente a MakerLoop, Inc., con sede a San Francisco, Stati Uniti.</p>
        <p className="mb-2">Questo sito installa Cookie di propria creazione o di “prima parte” solo per fini tecnici.</p>
        <p className="mb-2">I Cookie di terze parti trasmessi per il tramite del sito del Dott. Francesco Latini sono unicamente dei Cookie Tecnici</p>
        <p className="mb-2">Il sito <i>www.francescolatinipsicologo.it</i> ha la funzione di mero intermediario nell'installazione nel browser dell'<i>Utente</i> di Cookie provenienti da tali soggetti esterni, terze parti. Le categorie di Cookie utilizzate e il tipo di trattamento dei dati personali delle terze parti, sono quindi regolati in conformità all'Informativa resa dalle relative aziende e gestioni. Si invita pertanto l'<i>Utente</i> a visitare la seguente pagina per consultare le informative ed i moduli di consenso delle terze parti: <a href="https://www.google.com/policies/technologies/types">https://www.google.com/policies/technologies/types</a></p>

        <div className="grid grid-cols-6">
          <hr className="col-span-6 md:col-start-2 md:col-span-4 my-4 mx-5 text-grey h-0.5" />
        </div>

        <p className="mb-2">Il sito del Dott. Francesco Latini utilizza i seguenti servizi di Google, che producono relativi Cookie:</p>

        <p className="mb-2"><b>Google Analytics con IP anonymization (Google Inc.)</b></p>
        <p className="mb-2">Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
        <p className="mb-2">Luogo del trattamento: USA – <a href="https://www.google.com/intl/it/policies/privacy/">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=it">Opt Out</a>.</p>
        <p className="mb-2">Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”).</p>
        <p className="mb-2">I dati raccolti da Google servono a tracciare ed analizzare l’utilizzo del <i>Sito</i>, a compilare report e a condividere questi ultimi con ulteriori servizi sviluppati da Google.</p>
        <p className="mb-2">Google potrebbe utilizzare i dati personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario. Ad ogni buon conto, Google Analytics rende anonimo l’indirizzo IP dell’<i>Utente</i>. In altri termini Google abbrevia l'indirizzo rendendolo non identificabile, se non per finalità di tipo statistico ed eventualmente pubblicitario.</p>
        <p className="mb-2">Riferimenti utili per comprendere il funzionamento di Google Analytics:</p>
        <ul className="mb-2">
          <li><a href="https://www.google.com/analytics/terms/it.html">https://www.google.com/analytics/terms/it.html</a></li>
          <li><a href="https://www.google.com/privacypolicy.html">https://www.google.com/privacypolicy.html</a></li>
          <li><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a></li>
        </ul>

        <p className="mb-2"><b>Widget Google Maps (Google Inc.) [sezione del sito “Contatti”]</b></p>
        <p className="mb-2">Dati Personali raccolti: Cookie e Dati di utilizzo.</p>
        <p className="mb-2">Luogo del trattamento: USA – Privacy Policy – Opt Out.</p>
        <p className="mb-2">Questo tipo di servizi permette di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine del <i>Sito</i> e di interagire con essi.</p>
        <p className="mb-2">Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli <i>Utenti</i> non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato.</p>

        <div className="grid grid-cols-6">
          <hr className="col-span-6 md:col-start-2 md:col-span-4 my-4 mx-5 text-grey h-0.5" />
        </div>

        <p className="mb-2"><b>Durata dei Cookie</b></p>
        <p className="mb-2">Per ciò che concerne la durata dei Cookie, si precisa che taluni Cookie (Cookie di sessione) restano attivi solo fino alla chiusura del browser. Altri Cookie sopravvivono alla chiusura del browser e sono disponibili anche in successive visite dell'<i>Utente</i>.</p>
        <p className="mb-2">Questi sono i cosiddetti Cookie “persistenti” e la loro durata è stabilita dal server al momento della loro creazione. In certi casi la loro durata è limitata, in altri casi potrebbe essere illimitata.</p>
        <p className="mb-2">L'<i>Utente</i> può trovare informazioni su come gestire i Cookie con alcuni dei browser più diffusi ad esempio ai seguenti indirizzi: <a href="https://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies">Google Chrome</a>, <a href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop">Mozilla Firefox</a>, <a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac">Apple Safari</a>, <a href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft Internet Explorer</a> e <a href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft Edge</a>.</p>

        <p className="mb-2"><b>Consenso all’utilizzo dei Cookie</b></p>
        <p className="mb-2">Il consenso all'utilizzo ed installazione dei Cookie sul browser dell'<i>Utente</i> viene manifestato da quest’ultimo attraverso l'accettazione della prima Informativa breve contenuta nel banner a comparsa immediata di accesso al <i>Sito</i> sulla home page e su qualsiasi altra sua pagina che sia utilizzata dall'utente come primo accesso, avente il seguente testo:</p>
        <p className="mb-2 text-center">
          <em>Questo sito utilizza cookie per migliorare funzionalità e prestazioni.</em>
        </p>

      </article>
    </section>
  </Layout>;
};

export default CookiePolicyPage;
